const cardDict = {
  "as": "Ace of Spades",
  "2s": "Two of Spades",
  "3s": "Three of Spades",
  "4s": "Four of Spades",
  "5s": "Five of Spades",
  "6s": "Six of Spades",
  "7s": "Seven of Spades",
  "8s": "Eight of Spades",
  "9s": "Nine of Spades",
  "10s": "Ten of Spades",
  "js": "Jack of Spades",
  "qs": "Queen of Spades",
  "ks": "King of Spades",
  "ac": "Ace of Clubs",
  "2c": "Two of Clubs",
  "3c": "Three of Clubs",
  "4c": "Four of Clubs",
  "5c": "Five of Clubs",
  "6c": "Six of Clubs",
  "7c": "Seven of Clubs",
  "8c": "Eight of Clubs",
  "9c": "Nine of Clubs",
  "10c": "Ten of Clubs",
  "jc": "Jack of Clubs",
  "qc": "Queen of Clubs",
  "kc": "King of Clubs",
  "ah": "Ace of Hearts",
  "2h": "Two of Hearts",
  "3h": "Three of Hearts",
  "4h": "Four of Hearts",
  "5h": "Five of Hearts",
  "6h": "Six of Hearts",
  "7h": "Seven of Hearts",
  "8h": "Eight of Hearts",
  "9h": "Nine of Hearts",
  "10h": "Ten of Hearts",
  "jh": "Jack of Hearts",
  "qh": "Queen of Hearts",
  "kh": "King of Hearts",
  "ad": "Ace of Diamonds",
  "2d": "Two of Diamonds",
  "3d": "Three of Diamonds",
  "4d": "Four of Diamonds",
  "5d": "Five of Diamonds",
  "6d": "Six of Diamonds",
  "7d": "Seven of Diamonds",
  "8d": "Eight of Diamonds",
  "9d": "Nine of Diamonds",
  "10d": "Ten of Diamonds",
  "jd": "Jack of Diamonds",
  "qd": "Queen of Diamonds",
  "kd": "King of Diamonds",
}

export {cardDict}
